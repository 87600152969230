import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ContextProvider from "../context"
import classes from "./css/Cirugias.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Banner from "../components/Banner/Banner"

const Lesiones = () => {
  const data = useStaticQuery(graphql`
    query {
      lesion: file(relativePath: { eq: "lesion.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      futbol: file(relativePath: { eq: "futbol.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rugby: file(relativePath: { eq: "rugby.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ciclismo: file(relativePath: { eq: "ciclismo.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContextProvider>
      <Layout>
        <SEO title="Lesiones Deportivas" />
        <div className={classes.root}>
          <Banner content="Lesiones Deportivas" />
          <Img
            className={classes.heroImg}
            fluid={data.lesion.childImageSharp.fluid}
          />
          <h3 className={classes.title}>Lesiones Deportivas</h3>
          <p className={classes.content}>
            La creciente práctica de la actividad deportiva ha incrementado
            notablemente la consulta diaria por esta patología. Las nuevas
            técnicas nos ofrecen la posibilidad de reparar y reconstruir el
            miembro dañado en forma rápida y segura permitiendo la pronta
            recuperación del paciente y su vuelta al deporte con el mismo nivel
            de desempeño, ya sea en forma amateur y/o profesional.
          </p>
          <div className={classes.flexImageContainer}>
            <Img
              className={classes.flexItem}
              fluid={data.rugby.childImageSharp.fluid}
            />
            <Img
              className={classes.flexItem}
              fluid={data.futbol.childImageSharp.fluid}
            />
            <Img
              className={classes.flexItem}
              fluid={data.ciclismo.childImageSharp.fluid}
            />
          </div>
        </div>
      </Layout>
    </ContextProvider>
  )
}

export default Lesiones
